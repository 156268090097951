import React from 'react'
import './styles.scss'
import { Link } from 'react-router-dom';
import success from "../../../../assets/media/images/success.jpeg";

export const Success = () => {
  return (
    <div className="register-success">
      <img className="register-success__img" src={success}/>
      <p className="register-success__msg">GRACIAS POR REGISTRARSE, <br/>A LA BREVEDAD SE ESTARÁ CONTACTANDO UN ASESOR, PARA FINALIZAR EL REGISTRO</p>
      <Link to="/" className="register-success__btn"> Volver</Link>
    </div>
  )
}