import React from "react";
import success from "../../../assets//media/images/success.jpeg";
import styles from "./Success.module.css";

const Success = ({
  style = null,
  text = "",
}) => {
  return (
    <>
      <div>
        <img src={success} alt="success" />
      </div>
      <div>
        <h2 className={styles.title}>Exito!</h2>
        <p className={styles.text}>{text}</p>
      </div>
     
    </>
  );
};

export default Success;
