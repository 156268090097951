import React from 'react';
import PropTypes from 'prop-types';
import styles from './SimpleLayout.module.css';
import Header from '../Layout/Header/Header';
import Content from '../Layout/Content/Content';
import Footer from '../Layout/Footer/Footer';

const SimpleLayout = React.memo((props) => {
  const { children, contentClassName = '', headerContent } = props;

  return (
    <div className={styles.simpleLayout}>
      
      <Header>
        {headerContent}
      </Header>

      <Content className={contentClassName}>{children}</Content>

      <Footer />
    </div>
  );
});

SimpleLayout.propTypes = {};

SimpleLayout.defaultProps = {};

export default SimpleLayout;
