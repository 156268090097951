import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./ForgotPassword.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import FormLayout from "../../../components/Common/FormLayout/FormLayout";
import TextInput from "../../../components/Common/Input/TextInput";
import firebase from "firebase";
import Button from "../../../components/Common/Button/Button";
import { IsValidEmail } from "../../../lib/validator";
import "react-toastify/dist/ReactToastify.css";
import { notify } from "../../../lib/notify";
import Success from "../../../components/Common/Success/Success";
import { postData } from "../../../api/api.js";

const ForgotPassword = () => {
  const forgotForm = useRef(null);
  const [success, setSuccess] = useState(false);
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Por favor ingrese su email"
  );

  const handleSubmit = async (e) => {
    setSubmitted(true);
    e.preventDefault();
    const isValidEmail = IsValidEmail(phoneOrEmail);
    if (phoneOrEmail == "") {
      setHasError(true);
      setErrorMessage("Por favor ingrese su email");
      return;
    }

    if (isValidEmail) {
      try {
        const data = {
          email: phoneOrEmail
        }

        const response = await postData(
          process.env.REACT_APP_API_URL+'/usuarios/request-password',
          data
        );

        if(response.status === 'success'){
            clearForm();
            notify("success", "Email enviado");
            setSuccess(true);
            setSubmitted(false);
            setHasError(false);
        }else{
            notify("error", response.message);
        }
      } catch (error) {
        console.error(error);
        notify("error", "Error Inesperado");
      }
    } else {
      setHasError(true);
      setErrorMessage("Ingrese un email valido");
    }
  };

  const clearForm = async () => {
    forgotForm.current.reset();
  };

  return(  
      <>
        <FormLayout>
          {/* <!--begin::Forgot--> */}
          <div className={`login-form login-forgot ${styles.formContainer}`}>
            {/* <!--begin::Forms--> */}
           { success ? 
            <Success text ="Revisa tu email y encontrarás las instrucciones para cambiar tu contraseña" /> :
            <form
              ref={forgotForm}
              id="forgot-password-form"
              className="form d-flex flex-wrap"
              onSubmit={handleSubmit}
              name="kt_login_forgot_phone_form"
            >
              <div className={styles.formContainer}>
                <div className="pb-8 pt-lg-0 pt-5 w-100">
                  <h3
                    style={{ float: "left" }}
                    className="font-weight-boldest font-size-h1 text-uppercase"
                  >
                    Olvidé mi contraseña
                  </h3>
                </div>
                <div
                  style={{ float: "left", textAlign: "left", paddingTop: 20 }}
                  className="form-group"
                >
                  <TextInput
                    isRequired
                    hasError={hasError && submitted}
                    label="Email"
                    errorMessage={errorMessage}
                    name="phoneOrEmail"
                    onChangeText={setPhoneOrEmail}
                  />
                </div>
                <div className="form-group d-flex flex-wrap justify-content-center justify-content-lg-start pb-lg-0 pb-3">
                  <Button text="Enviar" />
                </div>
              </div>
            </form>
           
           }
          </div>
        </FormLayout>
        <Link to="/" className="go-home">
          <FontAwesomeIcon icon={faHome} className="icon-lg text-white" />
        </Link>
        {/* <!--begin::Main--> */}
      </>
    )

};

export default ForgotPassword;
