import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProteccionDeDatos.module.css';
import SimpleLayout from '../../../components/Common/SimpleLayout/SimpleLayout';


const ProteccionDeDatos = () => (
    <div className={styles.ProteccionDeDatos}>
        <SimpleLayout>

            <>

                {/* <!--begin::Entry--> */}
                <div className="d-flex flex-column-fluid">
                    {/* <!--begin::Container--> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mt-15">
                                <h1 className="mb-15 text-uppercase font-weight-boldest">Condiciones de uso y la política de privacidad</h1>
                                <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, et?</h2>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus blanditiis provident, dolores omnis nostrum dolorem tempora, illo ipsum sit asperiores minus facilis cumque odit distinctio beatae temporibus, cupiditate inventore laborum? Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum quos voluptatum accusamus optio voluptatem similique cumque minima quo nemo, aut quia numquam perspiciatis, officiis omnis iure neque obcaecati? Ex, totam? Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint, iste amet? Doloribus vitae quia repellendus eum similique quaerat optio quasi porro sed impedit accusamus, explicabo facilis voluptatibus! Deserunt, voluptates impedit. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minus voluptas delectus impedit cupiditate dolorum eveniet iusto voluptate rerum magni recusandae assumenda, odio tenetur nihil vero quas debitis, saepe deleniti blanditiis.</p>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam ipsa omnis quas soluta ratione. Impedit, nisi alias. Earum laudantium dolorum, incidunt esse nesciunt ipsam, vel impedit, optio ipsa molestias quos! Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt quasi minima nostrum minus architecto fugit iure excepturi debitis quam voluptatem ratione quia magni, quisquam, dolores commodi ex odit, fuga autem?</p>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, optio eligendi voluptatibus, aspernatur hic laborum at quisquam nulla quidem provident repudiandae! Quas eos harum tempore voluptas ut pariatur culpa accusamus! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum quidem, voluptates consectetur necessitatibus possimus quis dignissimos ratione excepturi maiores aut in eius? Laudantium nobis quibusdam laborum suscipit? Officia, modi temporibus? Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum beatae enim alias modi sunt architecto, est non consequuntur unde! Aut exercitationem modi delectus accusantium voluptatum inventore asperiores totam aliquam vitae?</p>
                                <h3>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam officiis delectus perspiciatis, vel neque repudiandae vitae quaerat dicta magni recusandae, deserunt atque quasi, ut illum facilis illo minima obcaecati nemo?</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat, in voluptate cupiditate eveniet sit similique ex ad excepturi. Saepe soluta illum nemo magni animi culpa iure expedita quisquam explicabo repudiandae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum perspiciatis at amet reiciendis assumenda quos nihil quas voluptate alias quod, quis a, porro voluptates officiis inventore totam asperiores rerum deserunt. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut laudantium, facere minima modi architecto expedita vel nesciunt molestiae ipsum aliquam ut pariatur consequatur vitae nisi illo blanditiis magni repudiandae cumque? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deleniti qui exercitationem quo, quisquam doloribus ullam et totam? Cumque consequuntur, beatae iusto veritatis, reiciendis, sequi incidunt minima accusamus ut inventore asperiores. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deleniti earum eveniet cum rerum iste. Doloremque libero quisquam iste animi omnis mollitia consequuntur odio tempore, necessitatibus expedita aliquam ab perspiciatis in!</p>
                                <h3>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam officiis delectus perspiciatis, vel neque repudiandae vitae quaerat dicta magni recusandae, deserunt atque quasi, ut illum facilis illo minima obcaecati nemo?</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat, in voluptate cupiditate eveniet sit similique ex ad excepturi. Saepe soluta illum nemo magni animi culpa iure expedita quisquam explicabo repudiandae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum perspiciatis at amet reiciendis assumenda quos nihil quas voluptate alias quod, quis a, porro voluptates officiis inventore totam asperiores rerum deserunt. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut laudantium, facere minima modi architecto expedita vel nesciunt molestiae ipsum aliquam ut pariatur consequatur vitae nisi illo blanditiis magni repudiandae cumque? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deleniti qui exercitationem quo, quisquam doloribus ullam et totam? Cumque consequuntur, beatae iusto veritatis, reiciendis, sequi incidunt minima accusamus ut inventore asperiores. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deleniti earum eveniet cum rerum iste. Doloremque libero quisquam iste animi omnis mollitia consequuntur odio tempore, necessitatibus expedita aliquam ab perspiciatis in!</p>
                            </div>
                        </div>
                    </div>
                    {/* <!--end::Container--> */}
                </div>
                {/* <!--end::Entry--> */}

            </>
        </SimpleLayout>
    </div>
);

ProteccionDeDatos.propTypes = {};

ProteccionDeDatos.defaultProps = {};

export default ProteccionDeDatos;