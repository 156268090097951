import React from 'react';
import PropTypes from 'prop-types';
import styles from './Patologias.module.css';
import asmaImg from '../../../../assets/media/images/IgorE-20190620-12008-min.jpg';
import lupusImg from '../../../../assets/media/images/GSK_V4L_BREAKFAST_01-min.jpg';
import hipertensionImg from '../../../../assets/media/images/IgorE-20190619-8908-Edit-min.jpg';
import CardPatologia from '../CardPatologia/CardPatologia';

const Patologias = () => (
  <section id="patologias" className="bg-light pt-40 pb-20">
    <div className="container">
      <div className="row">
        <CardPatologia
          facebookUrl={'https://www.facebook.com/SaberTeDaAire'}
          instagramUrl={'https://www.instagram.com/sabertedaaire'}
          img={asmaImg}
          redirectUrl={'https://gskyvos.com.ar/areasterapeuticas/respiratorio/asma/'}
          title={'Asma Grave Eosinofílica'}
          style={{ backgroundPosition: 'center' }}
        />
        <CardPatologia
          facebookUrl={'https://www.facebook.com/Conociendomilupus-102939064924841/'}
          instagramUrl={'https://www.instagram.com/conociendomilupus/'}
          img={lupusImg}
          redirectUrl={'https://gskyvos.com.ar/areasterapeuticas/lupus/'}
          title={'Lupus eritematoso sistémico'}
          style={{ backgroundPosition: 'right -80px bottom' }}
        />
        <CardPatologia
          img={hipertensionImg}
          redirectUrl={'https://gskyvos.com.ar/areasterapeuticas/hap/'}
          title={'Hipertensión Arterial Pulmonar'}
          style={{ backgroundPosition: 'right -120px bottom' }}
        />


      </div>
    </div>
  </section>
);

Patologias.propTypes = {};

Patologias.defaultProps = {};

export default Patologias;
