import React from "react";
import editMin from "../../../assets/media/images/IgorE-20190620-10247-Edit-min.jpg";
import programaMasImg from "../../../assets/media/logos/LOGO_MAS.png";
import gskLRGB from "../../../assets/media/logos/GSK_L_RGB.png";
import loginVisual from "../../../assets/media/svg/illustrations/login-visual-1.svg";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const FormLayout = React.memo((props) => {
  const { children } = props;

  return (
    <>
      {/* <!--begin::Main--> */}
      <div className="d-flex flex-column flex-root p-0">
        {/* <!--begin::Login--> */}
        <div style={{backgroundColor:'white'}}
          className="login login-1 d-flex flex-column flex-lg-row flex-column-fluid"
          id="kt_login"
        >
          {/* <!--begin::Aside--> */}
          <div
            style={{
              backgroundImage: "url(" + editMin + ")",
              width: "100%",
              maxWidth: "600px",
            }}
            className="login-aside d-flex flex-column flex-row-auto"
          >
            {/* <!--begin::Aside Top--> */}
            <Link to="/" className="text-center mb-10 logos">
                <img src={gskLRGB} style={{marginLeft: -20}} className="max-h-125px" alt="GSK" />
                <img
                  src={programaMasImg}
                  className="max-h-100px"
                  alt="Programa Mas"
                />
              </Link>
            {/* <!--end::Aside Top--> */}
            {/* <!--begin::Aside Bottom--> */}
            {/* <div
              className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
              style={{ backgroundImage: "url(" + loginVisual + ")" }}
            ></div> */}
            {/* <!--end::Aside Bottom-->} */}
          </div>
          {/* <!--begin::Aside--> */}
          {/* <!--begin::Content--> */}
          <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-10 mx-lg-auto">
            {/* <!--begin::Content body--> */}
            <div className="d-flex flex-column-fluid flex-center">
              {children}
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
                {/* Same as */}
            <ToastContainer />
            </div>
            {/* <!--end::Content body--> */}
          </div>
          {/* <!--end::Content--> */}
        </div>
        {/* <!--end::Login--> */}
      </div>
    </>
  );
});

FormLayout.propTypes = {};

FormLayout.defaultProps = {};

export default FormLayout;
