import React from 'react';
import styles from './Footer.module.css';
import gskLRGB from '../../../../assets/media/logos/GSK_L_RGB.png';
import { Link } from 'react-router-dom';


const Footer = () => (
	<div className={styles.Footer}>

		<div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
			<div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
				<div className="text-dark order-2 order-md-1">
					<span className="mr-2">NP-AR-DV-WCNT-220001</span>
					<span className="text-muted font-weight-bold mr-2">2021©</span>
					<a href="https://www.gsk.com/en-gb/contact-us/worldwide/argentina/es-arg/" target="_blank" className="text-dark-75 text-hover-primary">
						<img alt="Logo" src={gskLRGB} width="50" /></a>
				</div>
				<div className="nav nav-dark">
					<a href="/condiciones-de-uso" className="nav-link pl-0 pr-5">Condiciones de uso y la política de privacidad</a>
				</div>


			</div>


		</div>


	</div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
