import React from 'react';
import styles from './CardPatologia.module.css';
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const CardPatologia = ({ img, title, redirectUrl, facebookUrl, instagramUrl, style = {} }) => (
  <div className="col-12 col-md-6 col-lg-4 px-0 px-sm-10 mb-10 mb-lg-0">
    <div style={{ ...style, backgroundImage: "url(" + img + ")" }} className="patologias-banner">
      <div className="heartbeat mt-auto">
        <div className="heartbeat-text">
          <div className="d-flex flex-column justify-content-start align-items-start">
            <h3 className="font-weight-boldest text-uppercase text-left">{title}</h3>
            <div className="d-flex">
              {facebookUrl && <a href={facebookUrl} target="_blank" className={`btn btn-outline redes ${styles.iconLink} ${styles.link}`}><FontAwesomeIcon style={{ width: 40, height: 18 }} icon={faFacebookF} className="px-1" /></a>}
              {instagramUrl && <a href={instagramUrl} target="_blank" className={`btn btn-outline redes ${styles.iconLink} ${styles.link}`}><FontAwesomeIcon style={{ width: 40, height: 18 }} icon={faInstagram} className="px-1" /></a>}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center mt-auto">
            <a href={redirectUrl} target="_blank" className={`${styles.lined} ${styles.link}`}>Ver más</a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

CardPatologia.propTypes = {};

CardPatologia.defaultProps = {};

export default CardPatologia;
