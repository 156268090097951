import { Helmet } from "react-helmet";
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './App.css';
import Home from "./pages/public/Home/Home";
import Enrolamiento from "./pages/public/Enrolamiento/Enrolamiento";
import CondicionesDeUso from "./pages/public/CondicionesDeUso/CondicionesDeUso";
import ProteccionDeDatos from "./pages/public/ProteccionDeDatos/ProteccionDeDatos";
import Patologias from "./pages/components/Home/Patologias/Patologias";
import ForgotPassword from "./pages/public/ForgotPassword/ForgotPassword";
import ChangePassword from "./pages/public/ChangePassword/ChangePassword";
import Ingresar from "./pages/public/Ingresar/Ingresar";
import { ToastContainer } from "react-toastify";


function App() {
  return (
    <div className="App">
      <div className="application">
        <Helmet>
          <base href="" />
          <meta charset="utf-8" />
          <title>GSK Portal PSP Web</title>
          <meta name="description" content="Portal web de GSK." />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <script src="https://unpkg.com/babel-standalone@6/babel.min.js"></script>
          <link href="assets/plugins/custom/fullcalendar/fullcalendar.bundle.css" rel="stylesheet" type="text/css" />

          <link href="assets/plugins/global/plugins.bundle.css" rel="stylesheet" type="text/css" />
          <link href="assets/plugins/custom/prismjs/prismjs.bundle.css" rel="stylesheet" type="text/css" />
          <link href="assets/css/style.bundle.css" rel="stylesheet" type="text/css" />

          <link href="assets/css/themes/layout/header/base/light.css" rel="stylesheet" type="text/css" />
          <link href="assets/css/themes/layout/header/menu/light.css" rel="stylesheet" type="text/css" />
          <link href="assets/css/themes/layout/brand/light.css" rel="stylesheet" type="text/css" />
          <link href="assets/css/themes/layout/aside/light.css" rel="stylesheet" type="text/css" />

          <link href="assets/css/style-gsk.css" rel="stylesheet" type="text/css" />
          <link rel="shortcut icon" href="../src/assets/media/logos/favicon.ico" />


		<script src="assets/plugins/global/plugins.bundle.js"></script>
		<script src="assets/plugins/custom/prismjs/prismjs.bundle.js"></script>
		<script type="text/babel" src="assets/js/scripts.bundle.js"></script>
		<script src="assets/plugins/custom/fullcalendar/fullcalendar.bundle.js"></script>
		<script src="assets/js/pages/features/calendar/google.js"></script>
		<script src="assets/js/pages/widgets.js"></script>

        </Helmet>
      </div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => <Home />} />
          <Route exact path="/patologias" render={() => <Patologias />} />
          <Route exact path="/proteccion-de-datos" render={() => <ProteccionDeDatos />} />
          <Route exact path="/registro" render={() => <Enrolamiento />} />
          <Route exact path="/condiciones-de-uso" render={() => <CondicionesDeUso />} />
          <Route exact path="/forgot-password" render={() => <ForgotPassword />} />
          <Route exact path="/change-password/:passwordToken" render={() => <ChangePassword />} />
          <Route exact path="/ingresar" render={() => <Ingresar />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
