import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Ingresar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import FormLayout from "../../../components/Common/FormLayout/FormLayout";
import TextInput from "../../../components/Common/Input/TextInput";
import Button from "../../../components/Common/Button/Button";
import { IsValidEmail } from "../../../lib/validator";
import { notify } from "../../../lib/notify";
import { setCookie } from "../../../api/api";
import { request } from '../../../lib/request';

const Ingresar = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [hasUserError, setHasUserError] = useState(false);
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const [userErrorMessage, setUserErrorMessage] = useState(
    "Por favor ingrese su email"
  );
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(
    "Por favor ingrese su contraseña"
  );

  useEffect(() => { }, [hasPasswordError, hasUserError]);

  const IsFormValid = () => {
    let result = true;
    const isValidUser = IsValidEmail(user);
    setHasUserError(!isValidUser);
    setUserErrorMessage("Por favor ingrese un email valido");
    result = isValidUser && result
    setHasPasswordError(!password);
    setPasswordErrorMessage("Por favor ingrese su contraseña");
    result = password && result;
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (IsFormValid()) {
      setSubmitted(true);
      try {
        const respose = await request(process.env.REACT_APP_API_URL + "/login", {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: user,
            pwd: password
          })
        })
        if (respose.status == 'success') {
          setCookie("auth_token", respose.token, 365);
          window.location.href = process.env.REACT_APP_PRIVATE_PORTAL;
        } else {
          notify("error", "Email o Contraseña incorrectos");
        }

      } catch (error) {
        notify("error", "Tuvimos un error en el server, Intente de nuevo mas tarde");
      } finally {
        setSubmitted(false);
        setHasUserError(false);
        setHasPasswordError(false);
      }
    }
  };

  return (
    <div className={styles.Ingresar}>
      <FormLayout>
        <>
          {/* <!--begin::Login--> */}
          <div
            className="login login-1 d-flex flex-column flex-lg-row flex-column-fluid"
            id="kt_login"
          >
            <div className="login-aside d-flex flex-column flex-row-auto"></div>
            {/*<!--begin::Content--> */}
            <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-10 mx-lg-auto">
              {/* <!--begin::Content body--> */}
              <div className="d-flex flex-column-fluid flex-center">
                {/* <!--begin::Signin--> */}
                {/* style="display: inline;" */}
                <div className="login-form login-signin">
                  {/* <!--begin::Form--> */}

                  <form
                    className="form d-flex flex-wrap"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    name="kt_login_signin_form"
                  >
                    <div className={`${styles.formContainer}`}>
                      <div className="pb-8 pt-lg-0 pt-5 w-100">
                        <h3 className="font-weight-boldest font-size-h1 text-uppercase text-left">
                          Ingresar
                        </h3>
                      </div>
                      <div className="form-group">
                        <TextInput
                          isRequired
                          hasError={hasUserError && submitted}
                          label="Email"
                          errorMessage={userErrorMessage}
                          name="user"
                          onChangeText={setUser}
                          value={user}
                        />
                      </div>
                      <div className="form-group">
                        <TextInput
                          isRequired
                          hasError={hasPasswordError && submitted}
                          label="Contraseña"
                          errorMessage={passwordErrorMessage}
                          name="password"
                          onChangeText={setPassword}
                          value={password}
                          secureTextEntry={true}
                        />
                      </div>
                      <div className="form-group">
                        <p className="text-muted font-weight-bold mb-0 text-left">
                          ¿Olvidó su contraseña?{" "}
                          <a href="/forgot-password" className="text-primary">
                            Haga click aquí
                          </a>
                        </p>
                      </div>
                      <div className="form-group d-flex flex-wrap justify-content-center justify-content-lg-start pb-lg-0 pb-3">
                        <Button text="Ingresar" />
                      </div>
                    </div>
                  </form>
                  {/* <!--end::Form--> */}
                </div>
                {/* <!--end::Signin--> */}
              </div>
              {/* <!--end::Content body--> */}
            </div>
            {/* <!--end::Content--> */}
          </div>
          {/* <!--end::Login--> */}
        </>
      </FormLayout>

      <Link to="/" className="go-home">
        <FontAwesomeIcon icon={faHome} className="icon-lg text-white" />
      </Link>
    </div>
  );
};

Ingresar.propTypes = {};

Ingresar.defaultProps = {};

export default Ingresar;
