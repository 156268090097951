import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {useParams} from "react-router-dom";
import styles from "./ChangePassword.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import FormLayout from "../../../components/Common/FormLayout/FormLayout";
import TextInput from "../../../components/Common/Input/TextInput";
import Button from "../../../components/Common/Button/Button";
import { postData } from "../../../api/api.js";

import "react-toastify/dist/ReactToastify.css";
import { notify } from "../../../lib/notify";
import Success from "../../../components/Common/Success/Success";

const ChangePassword = () => {
    let { passwordToken } = useParams();
  const forgotForm = useRef(null);
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Por favor ingrese su email"
  );

  const handleSubmit = async (e) => {
    setSubmitted(true);
    e.preventDefault();

    if (password === "" || repeatPassword === "") {
      setHasError(true);
      setErrorMessage("Por favor ingrese su email");
      return;
    }

    if (password === repeatPassword) {
        const data = {
            password,
            token: passwordToken
        }
        try {
            const response = await postData(
              process.env.REACT_APP_API_URL + '/usuarios/change-password',
              data
            );

            if(response.status === 'success'){
                clearForm();
                notify("success", "Contraseña cambiada");
                setSuccess(true);
                setSubmitted(false);
                setHasError(false);
            }else{
                notify("error", response.message);
            }
          } catch (error) {
            console.error(error);
            notify("error", "Error Inesperado");
          }
    } else {
      setHasError(true);
      setErrorMessage("Las contraseñas deben coincidir");
    }
  };

  const clearForm = async () => {
    forgotForm.current.reset();
  };

  return(  
      <>
        <FormLayout>
          {/* <!--begin::Forgot--> */}
          <div className={`login-form login-forgot ${styles.formContainer}`}>
            {/* <!--begin::Forms--> */}
           { success ? 
            <Success text ="Tu contraseña fue modificada correctamente." /> :
            <form
              ref={forgotForm}
              id="forgot-password-form"
              className="form d-flex flex-wrap"
              onSubmit={handleSubmit}
              name="kt_login_forgot_phone_form"
            >
              <div className={styles.formContainer}>
                <div className="pb-8 pt-lg-0 pt-5 w-100">
                  <h3
                    style={{ float: "left" }}
                    className="font-weight-boldest font-size-h1 text-uppercase"
                  >
                    Cambiar contraseña
                  </h3>
                </div>
                <div
                  style={{ float: "left", textAlign: "left", paddingTop: 20 }}
                  className="form-group"
                >
                  <TextInput
                    isRequired
                    secureTextEntry={true}
                    hasError={hasError && submitted}
                    label="Contraseña"
                    errorMessage={errorMessage}
                    name="password"
                    onChangeText={setPassword}
                  />

                  <TextInput
                    isRequired
                    secureTextEntry={true}
                    hasError={hasError && submitted}
                    label="Repite tu contraseña"
                    errorMessage={errorMessage}
                    name="repeatPassword"
                    onChangeText={setRepeatPassword}
                  />
                </div>
                <div className="form-group d-flex flex-wrap justify-content-center justify-content-lg-start pb-lg-0 pb-3">
                  <Button text="Enviar" />
                </div>
              </div>
            </form>
           
           }
          </div>
        </FormLayout>
        <Link to="/" className="go-home">
          <FontAwesomeIcon icon={faHome} className="icon-lg text-white" />
        </Link>
        {/* <!--begin::Main--> */}
      </>
    )

};

export default ChangePassword;
