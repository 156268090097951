import React from 'react';
import PropTypes from 'prop-types';
import styles from './Content.module.css';

const Content = React.memo(({ children, className = '' }) => {
  return <div className={`${styles.content} ${className}`}>{children}</div>;
});

Content.propTypes = {};

Content.defaultProps = {};

export default Content;
