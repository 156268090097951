import React from "react";
import styles from "./Button.module.css";

const Button = ({
  isDisabled = false,
  onPress = () => {},
  style = null,
  text = "",
}) => {
  return (
    <button
      type="submit"
      id="#"
      onClick={onPress}
      style={style}
      disabled={isDisabled}
      style={{ fontWeight: "bold" }}
      className={`btn btn-primary font-size-h6 px-6 py-3 my-3 mr-4 ${isDisabled ? styles.buttonDisabled : ''}`}
    >
      {text}
    </button>
  );
};

export default Button;
