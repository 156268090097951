import React from "react";
import PropTypes from "prop-types";
import styles from "./App.module.css";
import iPhoneImg from "../../../../assets/media/images/iPhone-12-white.png";
import appStoreImg from "../../../../assets/media/images/appstore-white.svg";
import playStoreImg from "../../../../assets/media/images/google-play-white.svg";

const App = () => (
  <section id="app" className="bg-light py-20">
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-lg-6 col-xxl-4 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 className="text-uppercase font-weight-boldest text-white mb-16">
            Descargá la App
          </h1>
          <p className="text-white mb-15">
            Obtenga todos los beneficios del PROGRAMA MAS, Mejorando el acceso y
            la adherencia al tratamiento. Puede descargarlo desde los diferentes
            stores que se muestran a continuación.{" "}
          </p>
          <div className="mb-15 mb-lg-0">
            <a href="#">
              <img
                src={appStoreImg}
                alt="Appstore link"
                className="max-w-150px mb-5 mr-5"
              ></img>
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.elpixel.GSKProgramaMAS"
            >
              <img
                src={playStoreImg}
                alt="Google Play link"
                className="max-w-150px mb-5 mr-5"
              ></img>
            </a>
          </div>
        </div>
        <div className="col-12 col-lg-4 d-flex justify-content-center phone">
          <img
            src={iPhoneImg}
            alt="Iphone"
            className="max-w-250px"
            style={{ maxHeight: 500 }}
          ></img>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  </section>
);

App.propTypes = {};

App.defaultProps = {};

export default App;
