import React, { useCallback, useState } from "react";
import styles from "./Dropdown.module.css";

const Dropdown = React.memo(
  ({
    onChange = () => {},
    items = [],
    selectedOption = null,
    form,
    ...props
  }) => {
    const { placeholder, label, isRequired, errorMessage, hasError } = props;

    const handleChange = useCallback(
      (text) => {
        onChange(text);
      },
      [onChange]
    );

    return (
      <>
        <label className="font-size-h6 allign-left">
          {label}
          {isRequired && <span className="required">*</span>}
        </label>
        <div className="">
          <select
            onChange={handleChange}
            className="form-control font-size-h6"
            {...props}
          >
            {placeholder && <option value="">
              {placeholder}
            </option>}
            {items.map((item, index) => {
              return <option value={item.id}>{item.nombre}</option>;
            })}
          </select>
          {hasError && <label className={"error text-left"}>{errorMessage}</label>}
        </div>
      </>
    );
  }
);

export default Dropdown;
