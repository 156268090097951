import React, { useCallback, useState } from 'react';
import styles from "./TextInput.module.css";

const TextInput = React.memo(({ 
  field,
  value = '',
  onChangeText = () => {},
  secureTextEntry = false,
  form, ...props }) => {
  const [text, setText] = useState(value);
  const { name, label, isRequired, errorMessage, hasError } = props;

  const handleChangeText = useCallback(
    (text) => {
      setText(text);
      onChangeText(text);
    },
    [onChangeText],
  );

  return (
    <div className={styles.input}>
      <label className="font-size-h6 allign-left">
        {label}
        {isRequired && <span className="required">*</span>}
      </label>
      <input
        type={secureTextEntry ? 'password' : 'text'}
        onChange={(e) => handleChangeText(e.target.value)}
        name={name}
        value={text}
        className={"form-control h-auto p-3 font-size-h6"}
        {...props}
      />

      {hasError && <label className={styles.error}>{errorMessage}</label>}
    </div>
  );
});

TextInput.propTypes = {};

TextInput.defaultProps = {};

export default TextInput;
