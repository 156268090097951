import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./Enrolamiento.module.css";

export const ModalConsentimiento = ({ onAcepted, closeModal }) => {
  const [acceptConditions, setAcceptConditions] = useState(false);
  return (
    <Modal show onHide={closeModal} size="lg">
      <Modal.Header>
        <h3
          className="font-weight-boldest font-size-h1 text-uppercase mb-0"
          id="consentimientoModalLabel"
        >
          CONSENTIMIENTO PARA PARTICIPACIÓN EN EL PROGRAMA MEJOR ACCESO A LA
          SALUD Y PARA EL PROCESAMIENTO DE DATOS EN EL MARCO DEL PROGRAMA{" "}
        </h3>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body
        className="text-justify"
        style={{ height: "400px", overflowY: "scroll" }}
      >
        <p>
          Declaro que he comprendido la información que se me ha dado en
          relación con el programa de Mejor Acceso a la Salud (el{" "}
          <u>“Programa”</u>) y por medio del presente declaro mi voluntad de
          ingresar, participar y ser parte del Programa, o en su caso de que el
          menor de edad a quien represento sea ingresado, participe y sea parte
          del Programa.
        </p>
        <p>
          Por la presente doy mi consentimiento previo, expreso e informado en
          los términos de la Ley de Protección de Datos Personales N° 25.326
          (artículos 5 y 6), a GlaxoSmithKline Argentina S.A., con domicilio en
          Carlos Casares 3690, Victoria, Provincia de Buenos Aires (“<u>GSK</u>
          ”), como patrocinador del Programa, para que recolecten y procesen mis
          datos personales o los datos personales del menor de edad a quien
          represento que hayan sido suministrados por mí a GSK, incluyendo datos
          médicos y de salud (los “<u>Datos Personales</u>”), únicamente para
          poder ingresar, participar y ser contactado en el marco del Programa.
        </p>

        <p>
          Entiendo y acepto que la entrega de los Datos Personales tanto como la
          participación en el Programa es voluntaria. Sin embargo, entiendo que,
          si no consiento el procesamiento de los Datos Personales o si
          proporciono datos falsos o inexactos, ello podría impedir o dificultar
          la participación en el Programa.
        </p>
        <p>
          Asimismo, fui informado de que GSK utilizará los Datos Personales
          exclusivamente para cumplir con cualquier obligación legal a su cargo,
          para compartir información médica de su interés, para asegurar la
          correcta actualización, resguardo y procesamiento de los Datos
          Personales según la finalidad expresada en el presente y/o para
          controlar los servicios de quien procese los Datos Personales por
          cuenta y orden de GSK. Declaro conocer que podré ejercer el derecho a
          acceder, rectificar, actualizar y suprimir los Datos Personales
          mediante el envío de un correo electrónico a la dirección
          ar.programamas@gsk.com y he sido informado que la Agencia de Acceso a
          la Información Pública, en su carácter de Órgano de Control de la Ley
          N° 25.326, tiene la atribución de atender las denuncias y reclamos que
          interpongan quienes resulten afectados en sus derechos por
          incumplimiento de las normas vigentes en materia de protección de
          datos personales.
        </p>
        <p>
          Declaro conocer y aceptar que los Datos Personales que suministre a
          GSK, de modo directo o a través del médico tratante o del tercero que
          GSK eventualmente pueda designar, serán recolectados y tratados como
          confidenciales. Sin perjuicio de lo expuesto, GSK podrá divulgar y
          transferir total o parcialmente los Datos Personales a: (i) las
          autoridades de salud y demás agencias gubernamentales cuando ello sea
          necesario (por ejemplo, ANMAT); (ii) proveedores de servicios de GSK;
          (iii) afiliadas de GSK y/u otras empresas en caso de fusión y/o
          adquisición y/o escisión o cesión de negocio de GSK; y/o (iv)
          cualquier otra empresa o persona física cuando sea permitido o exigido
          por la ley. Declaro conocer y acepto que dichas transferencias de los
          Datos Personales podrán incluso realizarse a países que no provean el
          mismo nivel de protección que la Argentina, y que en estos casos, se
          tomarán las medidas de protección de datos personales pertinentes con
          el objeto de asegurar la confidencialidad y seguridad de los Datos
          Personales de acuerdo con los estándares de seguridad vigentes en
          Argentina.
        </p>
        <p>
          Asimismo, expresamente consiento que mis Datos Personales puedan ser
          anonimizados sin que se me identifique personalmente para ser
          utilizados y procesados para fines científicos y estadísticos y para
          ser compartidos en forma anonimizada y agregada con terceros para las
          mismas finalidades.
        </p>
        <p>
          Para más información sobre el tratamiento de los Datos Personales por
          favor consulta nuestro{" "}
          <a href="/condiciones-de-uso" target="_blank">
            Aviso de Privacidad
          </a>
          ”.
        </p>
        <div className="form-group fv-plugins-icon-container text-left">
          <label className="checkbox mb-0">
            <div className="ml-2">
              Acepto expresamente la recolección y el procesamiento de los Datos
              Personales por parte de GSK.
            </div>
            <input
              type="checkbox"
              name="modal-conficiones"
              value={acceptConditions}
              onChange={(value) => {
                setAcceptConditions(value.target.checked);
              }}
            />
            <span></span>
          </label>
        </div>
        <div className={styles.btnGroup}>
          <button
            type="button"
            className="btn btn-secondary font-size-h6"
            data-dismiss="modal"
            style={{ fontWeight: "bold" }}
            onClick={closeModal}
            style={{ marginRight: "16px" }}
          >
            Cancelar
          </button>
          <button
            onClick={onAcepted}
            disabled={!acceptConditions}
            type="submit"
            id="botonSubmit"
            className="btn btn-primary font-size-h6"
            style={{ fontWeight: "bold" }}
          >
            Aceptar
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
