import React from "react";
import styles from "./CondicionesDeUso.module.css";
import SimpleLayout from "../../../components/Common/SimpleLayout/SimpleLayout";

const CondicionesDeUso = () => (
  <div className={styles.CondicionesDeUso}>
    <SimpleLayout>
      <>
        <div className={`d-flex flex-column-fluid ${styles.content}`}>
          <div className="container">
            <div className="row">
              <div className="col-12 mt-15">
                <h1
                  style={{
                    fontSize: 40,
                    textAlign: "center",
                    color: "#F36633",
                  }}
                  className="mb-15 text-uppercase font-weight-boldest"
                >
                  Aviso de Privacidad
                </h1>
                <h3 style={{ fontWeight: 600, marginBottom: "16px" }}>
                  Última actualización [01/06/2022]
                </h3>
                <p>
                  Valoramos proporcionarle información a través de nuestras
                  interacciones con usted, ya sean interacciones en persona, a
                  través de nuestras plataformas digitales (como nuestras
                  aplicaciones móviles y sitios web) o una de las otras formas
                  en que puede elegir interactuar con nosotros.
                </p>
                <p>
                  Este Aviso de Privacidad explica cómo GlaxoSmithKline y su
                  grupo de compañías (“GSK,” “nosotros,” “a nosotros” y
                  “nuestro”) recopila, usa, procesa y comparte la información
                  personal que nos proporciona, o que podamos obtener o generar
                  de otra forma, que se relacione con usted y lo identifica.
                  Tómese un momento para leer el Aviso de Privacidad y, si tiene
                  alguna pregunta, sienta la libertad de comunicarse con GSK al
                  4725 8900.
                </p>
                <p>
                  Podremos modificar este Aviso de Privacidad periódicamente. Le
                  recomendamos que revise el Aviso de Privacidad regularmente.
                </p>
                <h2 className={styles.title}>Contenido</h2>
                <a href="#content1" className={styles.ancla}>
                  1. ¿Qué Información Personal recopilamos, y de dónde la
                  obtenemos?¿Cómo obtenemos Información Personal sobre usted?
                </a>
                <a href="#content2" className={styles.ancla}>
                  2. ¿Cómo usamos su Información Personal?
                </a>
                <a href="#content3" className={styles.ancla}>
                  3. A veces compartimos su Información Personal
                </a>
                <a
                  href="#content3-1"
                  className={styles.ancla}
                  style={{ paddingLeft: "18px" }}
                >
                  Transferencias fuera de su país de origen
                </a>
                <a
                  href="#content3-2"
                  className={styles.ancla}
                  style={{ paddingLeft: "18px" }}
                >
                  Sitios Web y aplicaciones móviles que no son de nuestra
                  propiedad ni controlamos
                </a>
                <a href="#content4" className={styles.ancla}>
                  4. ¿Cuánto tiempo conservaremos su Información Personal?
                </a>
                <a href="#content5" className={styles.ancla}>
                  5. ¿Cuán segura está su Información Personal?
                </a>
                <a href="#content6" className={styles.ancla}>
                  6. Ejercicio de sus Derechos en virtud de este Aviso de
                  Privacidad
                </a>
                <a
                  href="#content6-1"
                  className={styles.ancla}
                  style={{ paddingLeft: "18px" }}
                >
                  ¿Qué sucede si no quiere proporcionarnos su Información
                  Personal?
                </a>
                <a href="#content7" className={styles.ancla}>
                  7. Cookies y otras tecnologías
                </a>
                <a
                  href="#content7-1"
                  className={styles.ancla}
                  style={{ paddingLeft: "18px" }}
                >
                  Más información sobre cookies
                </a>
                <a href="#content8" className={styles.ancla}>
                  8. Usted tiene el control de su Información Personal
                </a>
                <p
                  href="#content8-1"
                  className={styles.ancla}
                  style={{ paddingLeft: "18px" }}
                >
                  Gestione las configuraciones de su cuenta
                </p>
                <p
                  href="#content8-2"
                  className={styles.ancla}
                  style={{ paddingLeft: "18px" }}
                >
                  Gestione los correos electrónicos promocionales
                </p>
                <p
                  href="#content8-3"
                  className={styles.ancla}
                  style={{ paddingLeft: "18px" }}
                >
                  Gestione las preferencias de cookies y otras
                </p>
                <p href="#content9" className={styles.ancla}>
                  9. Responsable de la Base de Datos
                </p>

                <h2 id="content1" className={styles.title}>
                  1. ¿Qué Información Personal recopilamos, y de dónde la
                  obtenemos?
                </h2>
                <p>
                  GSK y/o cualquier tercero contratado por GSK para la
                  administración del programa de soporte de pacientes de GSK
                  denominado “Mejor Acceso a la Salud” (el “Programa”) podrán
                  solicitarle que provea ciertos datos personales en forma
                  voluntaria, o podrá recopilar sus datos personales en forma
                  automática o por otros medios a través del sitio web del
                  Programa (el “Sitio Web”), a través de la aplicación móvil del
                  Programa (la “App”) o en virtud de su participación en el
                  Programa. Los datos personales incluyen, sin limitación,
                  cualquier dato personal que sea relevante a los fines de que
                  pueda participar en el Programa (en conjunto, la “Información
                  Personal”):
                </p>
                <ul className={styles.list}>
                  <li>
                    Nombre y apellido (en caso de tratarse de un menor de edad,
                    adicionalmente, nombre y apellido de quien ejerce su patria
                    potestad o representación);
                  </li>
                  <li>Número de Documento Nacional de Identidad;</li>
                  <li>Fecha de nacimiento;</li>
                  <li>
                    Datos de contacto (entre ellos domicilio, teléfono,
                    dirección de e-mail);
                  </li>
                  <li>Sexo;</li>
                  <li>
                    Datos médicos relacionados con la enfermedad a tratar (peso
                    corporal, resumen de historia clínica, resultados de
                    estudios, recetas de los médicos, productos farmacéuticos e
                    indicación, medicamentos prescriptos, declaraciones juradas,
                    partidas de nacimiento, copia del carnet de la obra social o
                    cobertura de salud, historial médico, resultados de los
                    análisis y estudios a realizarme en relación a mi
                    enfermedad);
                  </li>
                  <li>Preferencias de contacto;</li>
                  <li>Idioma;</li>
                  <li>
                    Determinada información de su cuenta de redes sociales
                    cuando haya elegido relacionar su cuenta de redes sociales
                    con su cuenta de GSK;
                  </li>
                  <li>
                    Datos de su actividad en el Sitio Web, en la App,
                    información de sus visitas a nuestros sitios webs y
                    aplicaciones móviles, incluido el tipo de navegador y
                    sistema operativo que usa, tiempos de acceso, páginas
                    visitadas, URL abiertos, su dirección de IP y la página que
                    visitó antes de navegar por nuestros sitios webs y
                    aplicaciones móviles;
                  </li>
                  <li>
                    Información del dispositivo, incluido identificador único de
                    dispositivo, modelo de hardware, sistema operativo y versión
                    e información de red móvil;
                  </li>
                  <li>Nombre y apellido del médico tratante;</li>
                  <li>
                    Sus respuestas a encuestas en las que elija participar,
                    incluidas respuestas a encuestas relacionadas con patologías
                    y datos de salud; y
                  </li>
                  <li>
                    Cualquier otro dato personal, sensible o no, que sea
                    relevante a los fines de que pueda participar en el
                    Programa.
                  </li>
                </ul>
                <p>
                  Usted garantiza y responde, en cualquier caso, por la
                  veracidad, exactitud, vigencia y autenticidad de la
                  Información Personal facilitada y se compromete a mantenerla
                  debidamente actualizada. Una vez registrado, usted dispondrá
                  de un nombre de usuario y contraseña que le permitirá el
                  acceso personalizado, confidencial y seguro al Sitio Web y App
                  del Programa. Usted se obliga a mantener la confidencialidad
                  de su clave de acceso, asumiendo totalmente la responsabilidad
                  por el mantenimiento de la confidencialidad de su clave
                  secreta registrada. Dicha clave es de uso personal y su
                  entrega a terceros, no involucra responsabilidad de GSK en
                  caso de uso inapropiado. Usted se compromete a notificar a GSK
                  en forma inmediata y por medio idóneo y fehaciente, cualquier
                  uso no autorizado de su cuenta, así como el ingreso por
                  terceros no autorizados a la misma.
                </p>

                <h2 id="content1-1" className={styles.title}>
                  ¿Cómo obtenemos Información Personal sobre usted?
                </h2>
                <p>
                  Recopilamos su Información Personal en el curso del monitoreo
                  de nuestras herramientas y servicios de tecnología, incluidas
                  las comunicaciones de correo electrónico que se envían y se
                  reciben de GSK. De otra forma, reunimos y generamos
                  información sobre usted cuando nos las proporciona o
                  interactúa con nosotros directamente o con nuestros
                  prestadores de servicios que actúan por cuenta y orden de GSK.
                  Por ejemplo, cuando se registra en una de nuestras
                  plataformas, también podremos recibir información sobre usted
                  de su médico tratante, compañías de redes sociales de
                  conformidad con sus procedimientos de autorización (por
                  ejemplo, cuando haya relacionado su cuenta de GSK con su
                  cuenta de redes sociales). Podremos combinar información que
                  tengamos sobre usted de diversas fuentes, incluida la
                  información que nos haya entregado.
                </p>

                <h2 id="content2" className={styles.title}>
                  2. Cómo usamos su Información Personal
                </h2>
                <p>
                  Usamos su Información Personal para las siguientes
                  finalidades: (i) administrar, organizar y posibilitar su
                  participación en el Programa, (ii) contactarnos e interactuar
                  con usted, responder sus consultas, procesar sus solicitudes y
                  consultas, (iii) brindarle información y orientación sobre los
                  formularios y documentación a presentar ante la financiadora
                  del sistema de salud para la cobertura de los medicamentos que
                  le prescriban (los “Medicamentos”) en el marco del Programa,
                  (iv) realizar recordatorios sobre las dosis de los
                  Medicamentos en el marco del Programa, (v) hacer seguimiento y
                  consultas relacionadas con el Programa, (vi) para grabar las
                  conversaciones telefónicas y monitorear y grabar los mensajes
                  de chatbot, (vii) enviar a su médico tratante información
                  sobre la cobertura de los Medicamentos, su adherencia en el
                  marco del Programa y los datos de los eventos adversos que
                  pudiera reportar, (viii) responder a sus solicitudes, mejorar
                  nuestro nivel de servicio y el contenido del Sitio Web y la
                  App, suministrarle información útil, noticias y
                  actualizaciones de productos; informarle sobre productos y
                  servicios nuevos; obtener su opinión sobre nuestros productos
                  y servicios y para análisis de mercado, (ix) enviar avisos
                  importantes (como cambios del Aviso de Privacidad), enviar
                  avisos técnicos, actualizaciones, alertas de seguridad y
                  mensajes de soporte y administrativos; y (x) cumplir con la
                  legislación aplicable y requerimientos de autoridades
                  competentes.
                </p>

                <p>
                  Su Información Personal es recolectada y procesada por un
                  tercero prestador de servicios que actúa por cuenta y orden de
                  GSK. GSK no tendrá acceso ni hará uso de su Información
                  Personal con fines de promoción de productos farmacéuticos y/u
                  otra finalidad de carácter comercial o promocional, sino que
                  serán utilizados para cumplir con cualquier obligación legal
                  de acceder a los mismos a cargo de GSK, y/o para asegurar la
                  correcta actualización, resguardo y procesamiento de su
                  Información Personal según la finalidad para la cual fueron
                  recolectados y/o controlar los servicios de quien procese la
                  Información Personal por cuenta y orden de GSK.
                </p>

                <p>
                  Asimismo, GSK podrá utilizar su Información Personal en forma
                  anonimizada sin que se lo identifique en forma personal, para
                  fines científicos y estadísticos, relacionados con la
                  investigación y el desarrollo de nuevos productos que pueden
                  mejorar las condiciones de vida de las personas. Dichos datos
                  anonimizados podrán ser compartidos por GSK con terceros.
                </p>

                <h2 id="content3" className={styles.title}>
                  3. A veces compartimos su Información Personal
                </h2>
                <p>
                  Es posible que compartamos su Información Personal con algunas
                  de las siguientes partes:
                </p>
                <ul className={styles.list}>
                  <li>
                    Nuestros proveedores de servicios que nos ofrece servicios
                    de respaldo tecnológico, terceros prestadores de servicios
                    que administran los programas de pacientes de GSK, como por
                    ejemplo Pharmexx Argentina S.A.) que procesarán su
                    Información Personal bajo instrucciones precisas de GSK;
                  </li>
                  <li>
                    Su médico tratante para enviarle información sobre la
                    cobertura de los Medicamentos, su adherencia en el marco del
                    Programa y los datos de los eventos adversos que pudiera
                    reportar;
                  </li>
                  <li>
                    Prestadores como por ejemplo centros de infusión,
                    enfermería, aplicación, terapia de atención y/o infusión en
                    el hogar (home infusion);
                  </li>
                  <li>
                    Durante un proceso de auditoría, con los auditores y sus
                    mandantes, siempre sujeto a obligación de confidencialidad;
                  </li>
                  <li>
                    Reguladores, gobiernos y autoridades competentes que exigen
                    el cumplimiento de la ley como, por ejemplo, la
                    Administración Nacional de Medicamentos, Alimentos y
                    Tecnología Médica (ANMAT) y otras autoridades de salud y
                    demás agencias gubernamentales cuando ello sea necesario; y
                  </li>
                  <li>
                    Otros terceros, afiliados o no a GSK, relacionados con la
                    venta, fusión, compra o reorganización de la totalidad o una
                    parte de nuestro negocio, o el desarrollo de cambios en
                    nuestro negocio (incluido cualquier comprador potencial o
                    real de ese negocio o los asesores de dicho comprador).
                  </li>
                </ul>

                <h2 id="content3-1" className={styles.title}>
                  Transferencias fuera de su país de residencia
                </h2>
                <p>
                  Es posible que realicemos la transferencia de su Información
                  Personal fuera de su país de residencia, con destino hacia
                  países que no proporcionan un nivel equivalente de protección
                  de su Información Personal al de las leyes de su país de
                  residencia.
                </p>
                <p>
                  Implementaremos medidas adecuadas para asegurar que su
                  Información Personal continúe protegida y segura cuando se
                  transfiera fuera de su país de residencia, de conformidad con
                  las leyes de protección de datos y de privacidad aplicables.
                  Estas medidas incluyen acuerdos de transferencias de datos que
                  implementan cláusulas de protección de datos estándar. En caso
                  de que GSK transfiera su Información Personal a países que la
                  Agencia de Acceso a la Información Pública (“AAIP”) no
                  considere que brinden un nivel adecuado de protección, GSK
                  utilizará el contrato modelo de transferencia de Información
                  Personal incluidos en la Disposición 60-E/2016 de la AAIP, o
                  la norma que pueda reemplazarla en un futuro.
                </p>

                <h2 id="content3-2" className={styles.title}>
                  Sitios web que no son de nuestra propiedad ni controlamos
                </h2>
                <p>
                  Periódicamente, podemos proporcionar enlaces a sitios web o
                  aplicaciones móviles que no son de nuestra propiedad ni
                  control. Este Aviso de Privacidad no se aplica a estos sitios
                  web o aplicaciones móviles. Si elige usar esos sitios web o
                  aplicaciones móviles, asegúrese de leer las declaraciones
                  legales y de privacidad publicadas en cada sitio web o
                  aplicación móvil a la que acceda para comprender sus prácticas
                  de privacidad.
                </p>

                <h2 id="content4" className={styles.title}>
                  4. ¿Cuánto tiempo conservaremos su Información Personal?
                </h2>
                <p>
                  Tenemos una política de retención de registros que estipula el
                  plazo durante el cual conservaremos su Información Personal.
                  Como regla general, conservamos su información personal: (a)
                  para cumplir con el fin para el cual se recopiló la
                  información; (b) en relación con una potencial acción legal o
                  una investigación que involucre a GSK; o (c) durante el tiempo
                  que sea necesario para cumplir con la ley y obligaciones
                  legales.
                </p>

                <h2 id="content5" className={styles.title}>
                  5. ¿Cuán segura está su Información Personal?
                </h2>
                <p>
                  Empleamos una variedad de tecnologías y procedimientos de
                  seguridad para ayudar a proteger su Información Personal del
                  acceso, uso o divulgación no autorizados. Nuestros proveedores
                  son seleccionados cuidadosamente y se les exige que utilicen
                  medidas adecuadas para proteger la confidencialidad y la
                  seguridad de su Información Personal. Lamentablemente, la
                  transmisión de información vía Internet o la conexión de red
                  de telefonía móvil pueden no ser completamente seguras;
                  cualquier transmisión queda bajo su propio riesgo.
                </p>

                <h2 id="content6" className={styles.title}>
                  6. Ejercicio de sus Derechos en virtud de este Aviso de
                  Privacidad
                </h2>
                <p>
                  Puede retirar su consentimiento para el procesamiento de la
                  Información Personal en cualquier momento, acceder, revisar,
                  corregir, actualizar, solicitar la supresión (en caso de
                  corresponder) y ejercer los derechos en relación con su
                  Información Personal bajo la legislación vigente en Argentina.
                  Daremos respuesta a sus requerimientos dentro de los plazos
                  legales determinados por la legislación aplicable.
                </p>
                <p>
                  Finalmente, en su capacidad de sujeto de datos, GSK le informa
                  al titular de la Información Personal que la AAIP, en su
                  carácter de Órgano de Control de la Ley Nro. 25.326, tiene la
                  atribución de atender las denuncias y reclamos que interpongan
                  quienes resulten afectados en sus derechos por incumplimiento
                  de las normas vigentes en materia de protección de información
                  personal.
                </p>
                <p>
                  Usted puede acceder, rectificar, actualizar y, en caso de
                  corresponder, solicitar la supresión de su Información
                  Personal brindados a GSK a través de del Sitio Web y la App,
                  en cualquier momento, comunicándose al 0800-777-0107 o al
                  correo electrónico{" "}
                  <a href="mailto:ar.programamas@gsk.com">
                    ar.programamas@gsk.com
                  </a>{" "}
                  . El prestador de servicios de procesamiento de datos que
                  actúe por cuenta y orden de GSK también procederá con la
                  actualización, rectificación, sustitución, supresión,
                  cancelación u oposición de su Información Personal en los
                  plazos legales.
                </p>

                <p>
                  De conformidad con la Resolución 14/2018 de la Agencia de
                  Acceso a la Información Pública, se le informa que la Agencia
                  de Acceso a la Información Pública, en su carácter de Órgano
                  de Control de la Ley N° 25.326, tiene la atribución de atender
                  las denuncias y reclamos que interpongan quienes resulten
                  afectados en sus derechos por incumplimiento de las normas
                  vigentes en materia de protección de datos personales.
                </p>

                <h2 id="content6-1" className={styles.title}>
                  ¿Qué sucede si no quiere proporcionarnos su Información
                  Personal?
                </h2>
                <p>
                  Cuando se le ofrezca la opción de compartir su Información
                  Personal con nosotros, siempre puede elegir no hacerlo.
                </p>
                <p>
                  Si decide no proporcionarnos su Información Personal, si
                  proporciona información inexacta o incompleta, o elige retirar
                  el consentimiento otorgado, respetaremos dichas solicitudes de
                  conformidad con nuestras obligaciones legales. No obstante,
                  esto podrá significar que no podremos realizar las acciones
                  necesarias para lograr los fines estipulados arriba,
                  incluyendo asegurar la correcta continuidad de su
                  participación en el Programa. También podrá significar que no
                  podrá hacer uso de los servicios y productos que ofrecemos.
                </p>
                <p>
                  Sin embargo, GSK podrá retener su Información Personal para
                  cumplir con obligaciones legales y reglamentarias, así como
                  para y proteger y ejercer nuestros derechos legales e
                  intereses.
                </p>

                <h2 id="content7" className={styles.title}>
                  7. Cookies y otras tecnologías
                </h2>
                <p>
                  Los sitios web y aplicaciones móviles de GSK, los servicios en
                  línea, las aplicaciones interactivas, mensajes de correo
                  electrónico, y avisos publicitarios podrán usar cookies y
                  otras tecnologías como identificadores de dispositivos para
                  mejorar su experiencia del usuario, ayudarnos a comprender
                  cómo se usan las plataformas, por ejemplo, qué partes de
                  nuestros sitio webs o aplicaciones móviles se han visitado y
                  qué correos electrónicos se han abierto, y medir la eficacia
                  de los avisos y servicios. Generalmente, tratamos la
                  información recolectada por las cookies y otras tecnologías
                  como información no personal. No obstante, en la medida que el
                  Protocolo de Internet (PI) u otros datos de identificación
                  similares sean considerados información personal por la ley
                  local, también tratamos a estos datos de identificación e
                  información recabada por cookies y otras tecnologías que se
                  pueden relacionar con estos datos de identificación, como
                  información personal.
                </p>

                <h2 id="content7-1" className={styles.title}>
                  Más información sobre cookies
                </h2>
                <p>
                  Para mejorar su experiencia en nuestros sitios web y
                  aplicaciones móviles, podremos usar “cookies.” Una cookie es
                  un código numérico único que se transfiere al navegador de su
                  computadora para realizar un seguimiento de sus intereses y
                  preferencias y reconocerlo como visitante habitual. La
                  información podrá ser recabada automáticamente por las cookies
                  y otras tecnologías. Para aprender cómo evitar que se recabe
                  información automáticamente, consulte la sección “Usted tiene
                  el control de su información personal” a continuación. Tenga
                  en cuenta que determinadas funciones de nuestros sitios web y
                  aplicaciones móviles no estarán disponibles si se desactivan
                  las cookies.
                </p>
                <p>Usamos cookies para diferentes fines:</p>
                <ul className={styles.list}>
                  <li>
                    <b>Operaciones esenciales</b>
                    <br />
                    Estas cookies son necesarios para permitirnos operar los
                    sitios web y aplicaciones móviles de GSK
                  </li>
                  <li>
                    <b>Funcionalidad</b>
                    <br />
                    Usamos cookies y otras tecnologías para cumplir con las
                    elecciones que ha hecho en cómo desea que las plataformas de
                    GSK funcionen cuando accede a las mismas. Por ejemplo,
                    usamos cookies funcionales para recordar información
                    personal cuando visita nuestros sitios web o aplicaciones
                    móviles para que su experiencia sea más conveniente y
                    personal.
                  </li>
                  <li>
                    <b>Rendimiento/Análisis</b>
                    <br />
                    Usamos cookies para analizar el rendimiento de nuestros
                    sitios web y aplicaciones móviles, para mantenerlos,
                    operarlos y mejorarlos continuamente. Usamos cookies para
                    analizar el uso de nuestros sitios web, aplicaciones móviles
                    y los canales de comunicación digitales. Por ejemplo,
                    podremos obtener información de las comunicaciones que
                    solicite, incluso si abrió o reenvío un correo electrónico o
                    si hizo clic en su contenido. Esto nos informa sobre nuestra
                    eficacia en las comunicaciones y nos ayuda a enviar
                    información que le resulte interesante.
                  </li>
                  <li>
                    <b>Terceros</b>
                    <br />
                    Usted podrá permitirles a nuestros socios comerciales usar
                    cookies en los sitios web y aplicaciones móviles de GSK a
                    los fines enumerados arriba.
                  </li>
                </ul>

                <h2 id="content8" className={styles.title}>
                  8. Usted tiene el control de su Información Personal
                </h2>
                <p>
                  Cuando nos haya entregado su Información Personal, hay varias
                  formas en que puede controlar cómo se procesa sin tener que
                  ejercer ninguno de los derechos detallados en este Aviso de
                  Privacidad.
                </p>

                <h2 id="content8-1" className={styles.title}>
                  Gestione las configuraciones de su cuenta
                </h2>
                <p>
                  Cuando se haya registrado para una cuenta con GSK, puede
                  gestionar cierta Información Personal a través de las
                  configuraciones de su cuenta.
                </p>

                <h2 id="content8-2" className={styles.title}>
                  Gestione los correos electrónicos promocionales
                </h2>
                <p>
                  Si no quiere recibir correos electrónicos de nosotros, puede
                  darle de baja a la suscripción usando el link en la parte
                  inferior de nuestro correo electrónico promocional. Cuando se
                  haya registrado para una cuenta con GSK, puede gestionar sus
                  preferencias de correo electrónico mediante las
                  configuraciones de cuenta.
                </p>

                <h2 id="content8-3" className={styles.title}>
                  Gestione las preferencias de cookies y otras
                </h2>
                <p>
                  Muchos buscadores web le permiten administrar sus
                  preferencias. Puede configurar su navegador para que rechace
                  cookies o elimine ciertas cookies. Puede gestionar otras
                  tecnologías de seguimiento de la misma forma que administra
                  las cookies usando las preferencias de su navegador.
                </p>
                <p>
                  Si decide negarse o eliminar cookies, tenga en cuenta que no
                  todos los elementos de las plataformas digitales de GSK podrán
                  funcionar de la forma que se pretende, de modo que puede verse
                  afectada su experiencia en nuestros sitios web o aplicaciones
                  móviles.
                </p>

                <h2 id="content9" className={styles.title}>
                  9. Responsable de la Base de Datos
                </h2>
                <p>
                  GlaxoSmithKline Argentina S.A., con domicilio en Carlos
                  Casares 3690, Victoria, Provincia de Buenos Aires, Argentina.
                  Teléfono +5411 4725 8900.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    </SimpleLayout>
  </div>
);

CondicionesDeUso.propTypes = {};

CondicionesDeUso.defaultProps = {};

export default CondicionesDeUso;
